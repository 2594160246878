<template>
  <div class="sideMenuTiny">
    <div v-show="menuVisible">
      <div @click="goUp" id="topVisible" class="sideMenu-item img1">
        <div class="slideMenu-item-img" style="width:18px;height:18px;"></div>
        <span>TOP</span>
      </div>
      <div class="sideMenu-item img0" @click="goCustomService">
        <div class="slideMenu-item-img" style="width:18px;height:18px;"></div>
        <span>在线客服</span>
        <div class="sub-info" style="height: 80px;"> 
          <img style="width:126px;height:54px;" src="../assets/phone-hang.png" alt="">
        </div>
      </div>
      <div class="sideMenu-item img2">
        <div class="slideMenu-item-img" style="width:18px;height:18px;"></div>
        <span>客服电话</span>
        <div class="sub-info" style="height: 80px;"> 
          <img style="width:126px;height:54px;" src="../assets/phone-hang.png" alt="">
        </div>
      </div>
      <div class="sideMenu-item img3">
        <div class="slideMenu-item-img" style="width:18px;height:18px;"></div>
        <span>公众号</span>
        <div class="sub-info">
          <img src="../assets/wechat-new.jpg" alt="">
        </div>
      </div>
      <div class="sideMenu-item img4">
        <div class="slideMenu-item-img" style="width:18px;height:18px;"></div>
        <span>小程序</span>
        <div class="sub-info">
          <img style="width:180px;height:180px;" src="../assets/wechat.png" alt="">
        </div>
      </div>
    </div>
    <div class="sideMenu-item img5" @click="hiddenMenu">
      <div
        :class="menuVisible ? 'slideMenu-item-img' : 'slideMenu-item-img-hidden'"
        style="width:18px;height:18px;"
      ></div>
    </div>
    <div v-if="showCS" class="close-btn" @click="showCS = false; memberId = ''"></div>
    <iframe class="m-iframe" v-if="showCS" :src="iframeSrc" frameborder="0"></iframe>
  </div>
</template>
<script>
import Vue from "vue";
import { CURRENT_USER } from "@/store/mutation-types";
window.onscroll = function() {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("topVisible").style.display = "flex";
  } else {
    document.getElementById("topVisible").style.display = "none";
  }
}

// 点击按钮，返回顶部
function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export default {
  data() {
    return {
      //   topVisible: true,
      menuVisible: true,
      showCS: false,
      memberId: '',
      nickName: '',
      iframeSrc: ''
    };
  },
  created(){
    this.memberId = '';
    this.nickName = '';
    let currentUser = Vue.ls.get(CURRENT_USER);
    this.memberId = currentUser && currentUser.memberId;
    this.nickName = currentUser && currentUser.name;
  },
  methods: {
    hiddenMenu() {
      this.menuVisible = !this.menuVisible;
    },
    goUp() {
      topFunction();
    },
    goCustomService(){
      let currentUser = Vue.ls.get(CURRENT_USER);
      this.memberId = currentUser && currentUser.memberId;
      this.nickName = currentUser && currentUser.name
      this.showCS = !this.showCS;
      let memberStr = ''
      if(this.memberId){
        memberStr += '&memberId=' + this.memberId
      }
      if(this.nickName){
        memberStr += '&nickName=' + this.nickName
      }
      this.iframeSrc = 'https://www.yiqu-service.com/livechatcust/pc_index.html#/?tenantId=5e2f11fb4a6c481f8c6dc3ee265a8647&sourceType=1' + memberStr
    }
  }
};
</script>
<style lang="less">
.sideMenuTiny {
  overflow: hidden;
  position: fixed;
  z-index: 100000;
  width: 56px;
  padding: 2px;
  border-radius: 6px;
  background: #f7f7f7;
  // box-shadow: 0 8px 24px rgb(104 44 152 / 16%);
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.15);
  right: 0px;
  bottom: 150px;
  display: block;
  border: 1px solid #f7f7f7;
}
.sideMenuTiny .sideMenu-item {
  height: 56px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  //   width: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 12px;
}
.sideMenuTiny .sideMenu-item:hover {
  background: #d70039;
  color: #ffffff;
}
.sideMenuTiny .sideMenu-item:hover span {
  color: #ffffff;
}
.sideMenuTiny .sideMenu-item span {
  color: #d70039;
  font-size: 10px;
  line-height: 18px;
  height: 20px;
  overflow: hidden;
}
.sideMenuTiny .sideMenu-item:hover {
  background: #d70039;
}
.sub-info {
  position: fixed;
  right: 70px;
  width: 180px;
  height: 180px;
  z-index: 1000;
  display: none;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -4px;
    width: 7px;
    height: 7px;
    border-top: 10px solid #ffffff;
    border-right: 10px solid #ffffff;
    transform: rotate(45deg);
  }
  img {
    width: 120px;
    height: 120px;
  }
}
.img0 {
  .slideMenu-item-img {
    background: url("../assets/service.png");
    background-size: cover;
  }
}
.img0:hover {
  .slideMenu-item-img {
    background: url("../assets/sercive_index_1.png");
    background-size: cover;
  }
}
.img1 {
  .slideMenu-item-img {
    background: url("../assets/top-index-1.png");
    background-size: cover;
  }
}
.img1:hover {
  .slideMenu-item-img {
    background: url("../assets/top-index-2.png");
    background-size: cover;
  }
}
.img2 {
  .slideMenu-item-img {
    background: url("../assets/phone-index-1.png");
    background-size: cover;
  }
}
.img2:hover {
  .slideMenu-item-img {
    background: url("../assets/phone-index-2.png");
    background-size: cover;
  }
  .sub-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.img3 {
  .slideMenu-item-img {
    background: url("../assets/wechat-index-1.png");
    background-size: cover;
  }
}
.img3:hover {
  .slideMenu-item-img {
    background: url("../assets/wechat-index-2.png");
    background-size: cover;
  }
  .sub-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.img4 {
  .slideMenu-item-img {
    background: url("../assets/qr-index-1.png");
    background-size: cover;
  }
  border-bottom: 1px solid #cccccc;
}
.img4:hover {
  .slideMenu-item-img {
    background: url("../assets/qr-index-2.png");
    background-size: cover;
  }
  .sub-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.img5 {
  .slideMenu-item-img {
    background: url("../assets/right-index-1.png");
    background-size: cover;
  }
  .slideMenu-item-img-hidden {
    background: url("../assets/right-index-1.png");
    background-size: cover;
  }
}
.img5:hover {
  .slideMenu-item-img {
    background: url("../assets/left-index-2.png");
    background-size: cover;
  }
  .slideMenu-item-img-hidden {
    background: url("../assets/left-index-2.png");
    background-size: cover;
  }
}
.close-btn{
  position: fixed;
  z-index: 1000;
  width: 13px;
  height: 13px;
  top: 105px;
  right: 105px;
  background: url("../assets/mark-close.png") 100% 100%;
  z-index: 10000;
  cursor: pointer;
}
.m-iframe{
  position: fixed;
  z-index: 1000;
  width: 360px;
  height: 500px;
  top: 100px;
  right: 100px;
}
</style>
