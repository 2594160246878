import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { EventBus } from "@/api/event-bus.js";
import {
  VueAxios
} from './axios'
import {
  ACCESS_TOKEN,CURRENT_USER
} from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' : '/api',
  timeout: 30000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)

    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // 匹配枚举类配置接口
  const enumRegex = new RegExp(/.*enum\/.*(Enum|List)/)
  const url = response.config.url
  if (url.match(enumRegex)) {
    const data = response.data
    const realData = data.obj
    const map = new Map()
    const listRegex = new RegExp(/.*List$/)
    if (url.match(listRegex)) {
      for (const item of realData) {
        map.set(item.text, item)
      }
    } else {
      for (const item of realData) {
        map.set(item.value, item)
      }
    }

    data.obj = map
  }

  if(!response.data.success  && response.data.errorCode=='checklogin'){
      Vue.ls.set(CURRENT_USER,{});
      console.log("没有权限");
      EventBus.$emit("loginout", '');
      window.location.href="/login?t="+new Date().getTime();
  }
 
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
