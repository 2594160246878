<template>
  <header :class="isHome ? 'nav_header nav_header_is_home' : (isLogin?'nav_header nav_header_is_login':'nav_header')">
    <div class="logo">
      <nav>
        <h1>
          <img
            v-if="isHome"
            @click="goHome"
            :src="logoImg"
            alt=""
            style="
              height: 50px;
              width: 223px;
              padding-left: 5px;
              padding-top: 5px;
              cursor: pointer;
            "
          />
          <img
            v-else
            @click="goHome"
            src="https://static.a6air.com/pc/common/logo-new-black-2.png"
            alt=""
            style="
              height: 50px;
              width: 223px;
              padding-left: 5px;
              padding-top: 5px;
              cursor: pointer;
            "
          />
        </h1>
        <div>
          <div>
            <hgroup>
              <div></div>
            </hgroup>
          </div>
          <ul class="menu">
            <li>
              <a href="/" target="_self" style="word-spacing: 20px">
                首 页
              </a>
            </li>
            <li style="position: relative">
              <a href="/ticket" target="_self">机票预订</a>
            </li>
            <li style="position: relative">
              <a href="/pss/promotion" target="_self">优惠促销</a>
              <!-- <dl class="redair-nav-child redair-anim redair-anim-upbit">
                <dd>
                  <a href="/mmb/list" target="_self">
                    <i class="redair-icon el-icon-present" style="display:block"></i>秒杀抢购
                  </a>
                </dd>
                <dd>
                  <a href="/mmb/search" target="_self">
                    <i class="redair-icon el-icon-shopping-cart-2"></i>周未特惠
                  </a>
                </dd>
              </dl>-->
            </li>
            <li style="display: none">
              <a href="/service/aoc" target="_self">旅游出行</a>
            </li>
            <li style="position: relative">
              <a href="/service/checkin" target="_self">自助服务</a>
              <dl
                class="redair-nav-child redair-anim redair-anim-upbit"
                style="width: 100px"
              >
                <dd>
                  <a href="/service/checkin" target="_self"> 自助值机 </a>
                </dd>
                <dd>
                  <a href="/service/aoc" target="_self"> 航班动态 </a>
                </dd>
                <dd>
                  <a href="/service/checkticket" target="_self"> 客票验真 </a>
                </dd>
              </dl>
            </li>
            <li style="position: relative">
              <a href="/pss/service?cid=0" target="_self">旅客服务</a>
              <dl
                class="redair-nav-child redair-anim redair-anim-upbit"
                style="width: 100px"
              >
              <dd v-for="(item, idx) in pssGroup" :key="idx">
                  <a :href="'/pss/service?cid='+idx" target="_self">{{item.keyWord}}</a>
              </dd> 
              <!-- <dd>
                  <a href="/pss/service?cid=0" target="_self"> 客票规则 </a>
              </dd>
              <dd>
                  <a href="/pss/service?cid=1" target="_self"> 行李须知 </a>
              </dd>
              <dd>
                  <a href="/pss/service?cid=2" target="_self"> 应急处置 </a>
              </dd>
              <dd>
                  <a href="/pss/service?cid=3" target="_self"> 服务协议 </a>
              </dd>
              <dd>
                  <a href="/pss/service?cid=4" target="_self"> 贴心服务 </a>
              </dd>
              <dd>
                  <a href="/pss/service?cid=5" target="_self"> 其他通知 </a>
              </dd> -->
              </dl>
            </li>
            <li style="position: relative; display: none">
              企业合作
              <dl class="redair-nav-child redair-anim redair-anim-upbit">
                <dd>
                  <a href="/cooperation" target="_self">
                    <i
                      class="redair-icon el-icon-collection"
                      style="display: block"
                    ></i
                    >API对接
                  </a>
                </dd>
                <dd>
                  <a href="/cooperation" target="_self">
                    <i class="redair-icon el-icon-s-cooperation"></i>红航货运
                  </a>
                </dd>
              </dl>
            </li>
            <li style="position: relative">
              <a href="/company/introduction" target="_self">关于我们</a>
              <dl
                class="redair-nav-child redair-anim redair-anim-upbit"
                style="width: 100px"
              >
                <dd>
                  <a href="/company/introduction" target="_self">
                    湖南航空
                  </a>
                </dd>
                <dd>
                  <a href="/company/culture" target="_self"> 企业文化 </a>
                </dd>
                <dd>
                  <a href="/company/model" target="_self"> 机型展示 </a>
                </dd>
                <dd>
                  <a href="/company/staff" target="_self"> 员工风采 </a>
                </dd>
                <dd>
                  <a href="/company/advertising" target="_self"> 广告招商 </a>
                </dd>

                <dd>
                  <a href="/company/contact" target="_self"> 联系我们 </a>
                </dd>
                <dd>
                  <a href="/company/news" target="_self"> 新闻公告 </a>
                </dd>
              </dl>
            </li>
            <li style="position: relative">
              <a href="/member/orderlist" target="_self">鸿鹄会</a>
              <dl
                class="redair-nav-child redair-anim redair-anim-upbit"
                style="width: 100px"
              >
                <dd>
                  <a href="/member/userinfo" target="_self"> 个人中心 </a>
                </dd>
                <dd>
                  <a href="/member/orderlist" target="_self"> 订单管理 </a>
                </dd>
                <dd>
                  <a href="/member/memberHand" target="_self"> 实名认证 </a>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nav-panel-wrapper">
          <!-- <figure>
            <i class="iconfont icon-telephone"></i>
            服务热线：
            <font style="color:#D70039;font-weight:bolder">400-833-7777</font>
          </figure>-->
          <figure class="link">
            <template
              v-if="currentUser.phone == undefined || currentUser.phone == ''"
            >
              <span style="padding-right: 8px">
                <a
                  href="/login"
                  :class="isHome ? 'colf' : 'col3'"
                  style="padding-left: 5px; float: left; margin-right: 2px"
                  target="_self"
                  >登&nbsp;录</a
                >
              </span>
              <span>
                <a
                  href="/reg"
                  :class="isHome ? 'colf' : 'col3'"
                  style="
                    float: left;
                    background: #d70039;
                    color: #ffffff;
                    border-radius: 2px;
                  "
                  target="_self"
                  >注&nbsp;册</a
                >
              </span>
            </template>
            <template
              v-if="currentUser.phone != undefined && currentUser.phone != ''"
            >
              <template v-if="isHome">
                <span style="padding-right: 8px">
                  <p style="padding-top: 3px; float: left">
                    <img src="../assets/honghu.png" />
                  </p>
                  <a
                    href="/member/userinfo"
                    style="color: #ffffff; padding-left: 10px; float: left"
                    target="_self"
                    >{{ currentUser.name }}</a
                  >
                  <a
                    href="javascript:void(0)"
                    style="color: #ffffff; padding-left: 10px; float: left"
                    target="_self"
                    @click="loginOutHandler"
                    >退&nbsp;出</a
                  >
                </span>
              </template>
              <template v-else>
                <span style="padding-right: 8px">
                  <p style="padding-top: 3px; float: left">
                    <img src="../assets/honghu.png" />
                  </p>
                  <a
                    href="/member/userinfo"
                    style="color: #909399; padding-left: 10px; float: left"
                    target="_self"
                    >{{ currentUser.name }}</a
                  >
                  <a
                    href="javascript:void(0)"
                    style="color: #909399; padding-left: 10px; float: left"
                    target="_self"
                    @click="loginOutHandler"
                    >退&nbsp;出</a
                  >
                </span>
              </template>
            </template>
          </figure>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import Vue from "vue";
import { CURRENT_USER } from "@/store/mutation-types";
import { loginOut, userinfo } from "@/api/newsapi";
import { EventBus } from "@/api/event-bus.js";
import { mapState } from "vuex";
import { pssgroup } from "@/api/newsapi";
export default {
  data() {
    return {
      isHome: false,
      isLogin:false,
      input: "",
      loginError: " ",
      currentUser: {},
      options: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      value: "",
      pssGroup: []
    };
  },
  watch: {
    $route(val) {
      this.isHome = val.name === "index" ? true : false;
      this.isLogin=val.name==="login"?true:false;
    },
  },
  computed: {
    logoImg() {
      return this.isHome
        ? "https://static.a6air.com/pc/common/logo-new-2.png"
        : "https://static.a6air.com/pc/common/logo-new-black-2.png";
    },
  },
  mounted() {
    document.getElementById("pageLoadingMask").style.display = "none";
    this.currentUser = Vue.ls.get(CURRENT_USER);
    this.loadPssGroupData();
    if (this.currentUser == null) {
      this.currentUser = {};
    }

    if (this.currentUser.userId != undefined) {
      userinfo({ t: {} }).then((res) => {
        if (res.success) {
          this.currentUser = res.data;
        }
      });
    }

    EventBus.$on("onlogin", (msg) => {
      this.currentUser = Vue.ls.get(CURRENT_USER);
    });

    EventBus.$on("loginout", (msg) => {
      this.currentUser = Vue.ls.get(CURRENT_USER);
    });
  },
  methods: {
    loginOutHandler() {
      this.loginError = " ";
      loginOut({}).then((res) => {
        if (res.success) {
          this.currentUser = {};
          Vue.ls.set(CURRENT_USER, {});
          this.$router.push({
            path: "/login",
            query: { t: new Date().getTime() },
          });
        }
      });
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    redirect(url) {
      console.log(url);
      window.location.href = url;
    },
    loadPssGroupData() {
      pssgroup({ aliasCode: "TCAT_PASSSERVICE" }).then(res => {
        if (res.success) {
          var tempGroup = res.data;
          this.pssGroup = [];
          tempGroup.forEach(item => {
            if (item.keyWord != "其它" && item.keyWord != "其他") {
              this.pssGroup.push(item);
            }
          });
        }
      });
    }
  },
};
</script>
<style scoped lang='less'>
.nav_header {
  z-index: 10000;
  /* margin: 0 auto; */
  width: 100%;
  background: #ffffff;
  color: #333333 !important;
  .logo {
    height: 80px !important;
    // border-bottom: 2px solid #d70039;
  }
}
.nav_header_is_home {
  position: absolute;
  background: transparent;
  z-index: 10000;
  width: 100%;
  .logo {
    border-bottom: 0 !important;
  }
  .logo nav .menu a {
    color: #ffffff;
  }
  .redair-anim a {
    color: #333333 !important;
  }
}
.nav_header_is_login {
  // position: absolute;
  // background: transparent;
  // z-index: 10000;
  width: 100%;
    color: #333333 !important;
  .logo {
    border-bottom: 0 !important;
  }
}
.menu-item:hover .nav-panel {
  top: 10px;
}
.nav-panel-wrapper {
  width: 300px;
  height: 35px;
  float: right;
  // margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.nav-panel {
  position: absolute;
  top: -500px;
  left: 0;
  width: 100%;
  /* background-color: #fff; */
  color: #333333 !important;
  padding: 20px 0;
  line-height: 25px;
  font-size: 12px;
}
.logo nav .menu {
  display: flex;
  padding-top: 5px;
}
.logo nav .menu a {
  /* color: #ffffff; */
  font-size: 14px;
}
.logo nav .menu li > a {
  font-size: 16px;
}
.logo nav .menu li {
  margin-left: 40px;
  font-size: 17px;
  cursor: pointer;
  width: 76px;
  height: 40px;
  font-family: "微软雅黑";
  text-align: center;
  /* font-weight: 600; */
  color: #525252;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
}
.logo nav .menu li:hover {
  // border-bottom: 2px solid #D70039;
  // color: #D70039;
}
.logo nav .menu li dl a {
  color: #333333;
  font-size: 14px;
}
.logo nav h1 {
  height: 100%;
  display: flex;
  align-items: center;
}
.logo nav {
  /* width: 1200px; */
  margin:10px auto;

  z-index: 100;
  display: flex;
  width: 1200px;
  align-items: center;
  justify-content: space-between;
  max-height: 640px;
  /* position: absolute;*/
  /* top: 0; */
   /*left: 50%;*/
   /*transform: translateX(-50%);*/
}
.logo {
  height: 90px;
  /* background-color: #fff; */
  position: relative;
  z-index: 101;
}
.link span + span {
  margin-left: 10px;
}
.link a {
  padding: 2px 5px;
}
// .link a:hover{
//   background: #D70039 !important;
//   cursor: pointer;
//   border-radius: 2px;
// }
.dropdown:hover {
  background-color: #ffffff;
}
.dropdown:hover .sub-nav {
  display: block;
}
.dropdown:hover p {
  background-position: right -12px;
  color: #223c61;
}
.dropdown p {
  color: #909399;
}
.sub-nav {
  background-color: #ffffff;
  padding: 20px 20px 0 20px;
  top: 39px;
  left: -1px;
  right: auto;
  border: none;
  display: none;
  position: absolute;
  color: #333333;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); */
}
.dropdown p {
  height: 40px;
  line-height: 40px;
  color: #909399;
  padding: 0 15px 0 10px;
}
.nav-item a {
  color: #909399;
}
.menu a {
  color: #000000;
}
header hgroup figure {
  line-height: 40px;
  font-size: 15px;
  color: #909399;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}
header hgroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  /* width: 1366px; */
  position: relative;
}
header {
  /* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1); */
}
.top {
  height: 40px;
  position: relative;
  z-index: 110;
  background-color: #ffffff;
  display: none;
  /* background-color: #f7f7f7; */
  /* background-color: #929ba0*/
}

.menu li .redair-nav-child {
  top: 42px;
  left: -50px;
  // padding: 10px 10px 0 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 2px;
  border: 1px solid #f9f9f9;
  text-align: left;
  min-width: 60px;
  /* background: #ffffff; */
  color: #333 !important;
  transform: translate3d(35px, 0px, 0px);
}
.header-block .hnaui-nav * {
  font-size: 16px;
}
.redair-anim-upbit {
  -webkit-animation-name: hnaui-upbit;
  animation-name: hnaui-upbit;
}
/* .hnaui-anim {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
} */
.redair-anim {
  background: #ffffff;
  position: relative;
  animation: mymove 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  /* Safari 和 Chrome */
  -webkit-animation: mymove 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
}
.redair-anim dd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.redair-anim dd:hover {
  background: #eeebeb;
}

@keyframes mymove {
  from {
    top: 30px;
  }
  to {
    top: 22px;
  }
}

@-webkit-keyframes mymove /* Safari 和 Chrome */ {
  from {
    top: 30px;
  }
  to {
    top: 22px;
  }
}
.redair-nav-child {
  display: none;
  position: absolute;
  left: -50px;
  top: 25px;
  min-width: 100%;
  line-height: 28px;
  font-size: 14px;
  // padding: 10px 10px 0 10px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #d2d2d2;
  /* background-color: #fff; */
  z-index: 1000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  white-space: nowrap;
}

.redair-nav-child dd {
  /* display: inline-block; */
  min-width: 85px;
  text-align: left !important;
  line-height: 40px;
  /* margin: 0 5px; */
}

.redair-show {
  display: block !important;
}

.menu li:hover .redair-nav-child {
  display: block !important;
}

.menu li:link .redair-nav-child {
  display: none !important;
}

dl {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}
.header-block .redair-nav li {
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
}
.header-block .redair-nav * {
  font-size: 16px;
}
.redair-nav .redair-nav-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 60px;
}
.redair-nav * {
  font-size: 14px;
}
.redair-icon {
  display: block;
  font-size: 27px;
  margin-bottom: 5px;
}

.header-block .redair-nav * {
  font-size: 16px;
}
.hnaui-nav * {
  font-size: 14px;
}
.redair-icon,
.redair-btn,
img,
.redair-change-icon,
.redair-unselect,
.redair-hnapage {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.colf {
  color: #ffffff !important;
}
.col3 {
  color: #333333;
  &:hover {
    // color: #ffffff !important;
  }
}
</style>