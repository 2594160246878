<template>
  <div id="app">
    <header-nav  />

    <div style="width:100%">
      <router-view></router-view>
    </div>
    <footer-nav />
    <HangPanel v-if="isPC()" />
    <Notice />
  </div>
</template>

<style>
  .gray_event{
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
      filter: grayscale(100%);
      filter: gray;
  }
  strong {
    font-weight: 600;
    font-style: normal;
  }
  #app{
    background: #f6f6f6 !important;
    width: 100%;
    min-height: 100%;
    position: relative;
  }
  .container {
    width: 1200px;
    margin: auto;
    /* margin-top: 90px; */
  }
  .bottomnotify{
    width:380px;
    opacity:0.7;
    border: none;
    margin-bottom: 25px;
    background-color: #000000;/*424141*/
    filter:alpha(opacity=50); /* 针对 IE8 以及更早的版本 */
}

.bottomnotify p{
   color:#FFFFFF
}
.bottomnotify h2{
  color:#FFFFFF;
  font-weight: 400;
}
  .el-button--danger {
    color: #FFF;
    background-color: #ff0000;
    border-color: #F56C6C;
}
.el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #d60039;
    border: 1px solid #DCDFE6;
    color: #FFFFFF;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 2px;
}


.el-step__title.is-success {
    color: #e62043;
}
.el-step__head.is-success {
    color: #e62043;
    border-color: #e62043;
}
.el-step__title.is-process {
    font-weight: 700;
    color: #e62043;
}
.el-step__head.is-process {
    color: #d44040;
    border-color: #d44040;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #d44040;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #d44040;
    background: #d44040;
}
.el-button--primary.is-active, .el-button--primary:active {
    background: #d44040;
    border-color: #d44040;
    color: #FFF;
}
.el-button--primary:focus, .el-button--primary:hover {
    background: #d44040;
    border-color: #d44040;
    color: #fff;
}
.el-button--primary.is-active, .el-button--primary:active {
    background: #d44040;
    border-color: #d44040;
    color: #fff;
}
</style>
<script>
import HeaderNav from './components/Header';
import FooterNav from './components/Footer2';
import HangPanel from './components/HangPanel';
import Notice from "./components/Notice";
import '@/assets/theme/element-redair/cascader.css'
import '@/assets/theme/element-redair/tabs.css'
import '@/assets/theme/element-redair/upload.css'
import { queryDict } from "@/api/newsapi";

export default {
  components: {
    HeaderNav,
    FooterNav,
    HangPanel,
    Notice
  },
  mounted(){
    queryDict({key:'GRAY_EVENT'}).then(res => {
     if (res.success && res.data ==1) {
        document.getElementsByTagName('html')[0].classList.add('gray_event')
     }else{
        document.getElementsByTagName('html')[0].classList.remove('gray_event')
     }
     });
  },
  methods:{
    isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }
  }
}
</script>
