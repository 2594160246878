const home = {
  state: {
    isHome: false
  },
  mutations: {
    SET_HOME: (state, isHome) => {
        state.isHome = isHome
    }
  },
  actions: {
  }
}

export default home
